import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['days', 'hours', 'minutes', 'seconds']
    static values = {
        date: String
    }

    getTimeRemaining(endtime) {
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor( (total/1000) % 60 );
        const minutes = Math.floor( (total/1000/60) % 60 );
        const hours = Math.floor( total/(1000*60*60) % 24 );
        const days = Math.floor( total/(1000*60*60*24) );
      
        return {
            total,
            days,
            hours,
            minutes,
            seconds
        };
    };

    update(t) {
        this.daysTarget.innerHTML = String(t.days).padStart(2, '0');
        this.hoursTarget.innerHTML = String(t.hours).padStart(2, '0');
        this.minutesTarget.innerHTML = String(t.minutes).padStart(2, '0');
        this.secondsTarget.innerHTML = String(t.seconds).padStart(2, '0');
    }

    connect() {
        this.update(this.getTimeRemaining(this.dateValue));

        const timeinterval = setInterval(() => {
            const t = this.getTimeRemaining(this.dateValue);
            this.update(t);
    
            if (t.total <= 0) {
                clearInterval(timeinterval);
            }
        }, 1000);

        this.element.classList.add('play');
    }
}

export const debounce = (callback, wait) => {
    let timeout;
    return function(e) {
        clearTimeout(timeout);
    
        timeout = setTimeout(() => {
            callback(e);
        }, wait);
    }
};

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['overlay', 'open', 'close']
    static values = {
        openClass: { type: String, default: 'info-tiles--open' },
        overlayClass: { type: String, default: 'info-tiles__overlay--open'},
        bodyClass: { type: String, default: 'info-tiles-overlay-open' }
    }

    open(event) {
        document.body.classList.add(this.bodyClassValue);
        this.element.classList.add(this.openClassValue);
        event.currentTarget.setAttribute('aria-expanded', 'true');
        const overlayId = event.currentTarget.getAttribute('data-overlay-id');

        this.overlayTargets.forEach((overlay) => {
            overlay.classList.remove(this.overlayClassValue);

            if (overlay.id === overlayId) {
                overlay.classList.add(this.overlayClassValue);

                this.closeTargets.forEach((close) => {
                    if (overlay.contains(close)) {
                        close.focus();
                    }
                });
            }
        });
    }

    close() {
        this.overlayTargets.forEach((overlay) => {
            if (overlay.classList.contains(this.overlayClassValue)) {
                this.openTargets.forEach((open) => {
                    if (overlay.id === open.getAttribute('data-overlay-id')) {
                        open.focus();
                    }
                });
            }
        });

        document.body.classList.remove(this.bodyClassValue);
        this.element.classList.remove(this.openClassValue);
    }

    documentClick(event) {
        let outsideOverlay = true;
        let outsideOpen = true;

        this.overlayTargets.forEach((overlay) => {
            if (overlay.contains(event.target)) {
                outsideOverlay = false;
            }
        });

        this.openTargets.forEach((open) => {
            if (open.contains(event.target)) {
                outsideOpen = false;
            }
        });

        if (outsideOverlay && outsideOpen) {
            this.close();
        }
    } 
}
